export const environment = {
  production: true,
  // API_BASE_PATH: "https://paradisedev.appskeeper.com/v1/",
  API_BASE_PATH: "https://api.paradisefoodcourt.in/v1/",
  // API_BASE_PATH: 'https://apistg.paradisefoodcourt.in/v1/',  //staging
  API_LOCAL_PATH: "http://localhost:4200/assets/json/",
  IMAGE_BASE_URL: "https://appinventiv-development.s3.amazonaws.com/",
  API_KEY: "paradise",
  FINGER_PRINT: "Bearer",
  tokenKey: "paradise-biryani",
  razerpay_key_id: "rzp_live_q9rcDt5MLzgySK",
  googleKey: "ELycepUja-hUJVpRLr6RjO7s",
  googleSocialId:
    "919987264792-5tnakmmj9a0vvk7u6tklmhf27c4ovfq1.apps.googleusercontent.com",
  // facebookKey: "1076271659458275",
  // facebookKey: "1199698393754763",
  // facebookKey: "433886811353574",
  facebookKey: "1199698393754763",
  guestKey: "paradise-guest",
  addressKey: "paradise-address",
  localAddressKey: "local-address",
  // googleAnalyticsKey : "G-WSRR9RNFC8",
  //googleAnalyticsKey: "G-BKSYDFP8P6",
  googleAnalyticsKey: "G-BKSYDFP8P6",
  mapAPIKey: "AIzaSyDyU-zGrO6VMV8-6e0NUQwx0S9cvW7fZe4",

  firebaseConfig: {
    apiKey: "AIzaSyAsNqWkJ3p8TGMe22Pp3hnvNMxgsttOR94",
    authDomain: "paradise-biryani-production.firebaseapp.com",
    databaseURL: "https://paradise-biryani-production.firebaseio.com",
    projectId: "paradise-biryani-production",
    storageBucket: "paradise-biryani-production.appspot.com",
    messagingSenderId: "919987264792",
    appId: "1:919987264792:web:bf8306a5fc5108f51f926d",
    measurementId: "G-YQ0HQW9JVW"
  },
};

